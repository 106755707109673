import _ from "lodash"
import {
    CartesianGrid,
    Line,
    LineChart as RechartsLineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts"
import { Granularity, InsightDateResult } from "../../../types/Insights"
import tailwindColors from "tailwindcss/colors"
import {
    getSimpleDateWithoutYear,
    getStartOfWeek,
    getMonth,
} from "../../../utils/datetime"
import { useEffect, useMemo, useState, useCallback } from "react"
import { ToggleExpandedViewButton } from "./ToggleExpandedViewButton"
import { createCustomTooltip } from "./CustomTooltip"
import { SelectionState } from "./SelectionState"
import { Legend } from "./Legend"

const NUMBER_OF_TOP_OPTIONS = 5

const getColorTable = (shade: keyof typeof tailwindColors.amber): string[] => [
    tailwindColors.amber[shade],
    tailwindColors.indigo[shade],
    tailwindColors.emerald[shade],
    tailwindColors.rose[shade],
    tailwindColors.sky[shade],
    tailwindColors.orange[shade],
    tailwindColors.violet[shade],
    tailwindColors.lime[shade],
    tailwindColors.fuchsia[shade],
    tailwindColors.teal[shade],
    tailwindColors.yellow[shade],
    tailwindColors.blue[shade],
    tailwindColors.red[shade],
    tailwindColors.green[shade],
    tailwindColors.pink[shade],
    tailwindColors.cyan[shade],
]

function getLabelFormatter(granularity: Granularity) {
    switch (granularity) {
        case Granularity.WEEKLY:
            return getStartOfWeek
        case Granularity.MONTHLY:
            return getMonth
        default:
            return getSimpleDateWithoutYear
    }
}

function calculateMaxValue(
    data: InsightDateResult[],
    isOptionSelected: (option: string) => boolean
): number {
    const maxValues = data.map((result) => {
        return Math.max(
            ...[
                1,
                // We're getting values only for selected options
                ...Object.entries(result.values)
                    .filter(([key, _]) => isOptionSelected(key))
                    .map(([_, value]) => value),
            ]
        )
    })
    return Math.max(...maxValues)
}

export function LineChart(props: {
    insightName: string
    granularity: Granularity
    data: InsightDateResult[]
}) {
    const [selectionState, setSelectionState] = useState<SelectionState>({
        selected: new Set(),
        hovered: null,
    })
    const [viewAllOptions, setViewAllOptions] = useState(false)

    const sortedOptions = useMemo(
        () => getOptionsSortedByTotal(props.data),
        [props.data]
    )

    // This contains only the visible options. Used to display the right
    // collapsed number of options in the legend. Should be the same as
    // `sortedOptions` when showing the expanded view.
    const visibleOptions = useMemo(() => {
        if (viewAllOptions) {
            return sortedOptions
        }
        return sortedOptions.slice(0, NUMBER_OF_TOP_OPTIONS)
    }, [sortedOptions, viewAllOptions])

    // This resets the selected options when the user toggles the "View all"
    // to collapse again.
    useEffect(() => {
        setSelectionState((prevState) => ({
            ...prevState,
            selected: new Set(visibleOptions.map(([key, _]) => key)),
        }))
    }, [visibleOptions])

    const isOptionSelected = useCallback(
        (option: string) => {
            return (
                selectionState.hovered === option ||
                (!selectionState.hovered && selectionState.selected.has(option))
            )
        },
        [selectionState]
    )

    const toggleOption = useCallback((option: string) => {
        setSelectionState((prevState) => {
            const newSelected = new Set(prevState.selected)
            if (newSelected.has(option)) {
                newSelected.delete(option)
            } else {
                newSelected.add(option)
            }
            return { ...prevState, selected: newSelected }
        })
    }, [])

    const setHoveredOption = useCallback((option: string | null) => {
        setSelectionState((prevState) => ({ ...prevState, hovered: option }))
    }, [])

    return (
        <div className="flex flex-col bg-white rounded-lg p-6 border border-gray-200 w-full">
            <h2 className="text-2xl font-bold mb-3 text-gray-800">
                {props.insightName}
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 w-full bg-gray-50 rounded-lg p-4">
                <div className="col-span-4 lg:col-span-1 pt-5">
                    <div className="flex flex-col gap-2">
                        <Legend
                            colors={getColorTable("500")}
                            totalCounts={visibleOptions}
                            selectionState={selectionState}
                            toggleSelectedOption={toggleOption}
                            setHoveredOption={setHoveredOption}
                            isOptionSelected={isOptionSelected}
                        />
                        {sortedOptions.length > NUMBER_OF_TOP_OPTIONS && (
                            <ToggleExpandedViewButton
                                viewAllOptions={viewAllOptions}
                                setViewAllOptions={setViewAllOptions}
                            />
                        )}
                    </div>
                </div>
                <div className="col-span-4 lg:col-span-3 h-96 w-full">
                    <LineChartView
                        data={props.data}
                        granularity={props.granularity}
                        isOptionSelected={isOptionSelected}
                        sortedOptions={sortedOptions}
                    />
                </div>
            </div>
        </div>
    )
}

function getOptionsSortedByTotal(
    results: InsightDateResult[]
): [string, number][] {
    const totalCounts: Record<string, number> = {}
    for (const result of results) {
        for (const [key, value] of Object.entries(result.values)) {
            totalCounts[key] = (totalCounts[key] || 0) + value
        }
    }

    return _.sortBy(Object.entries(totalCounts), ([_, v]) => -v)
}

function LineChartView(props: {
    data: InsightDateResult[]
    granularity: Granularity
    sortedOptions: [string, number][]
    isOptionSelected: (option: string) => boolean
}) {
    const data = props.data
    const toLabel = getLabelFormatter(props.granularity)
    const isOptionSelected = props.isOptionSelected
    const sortedOptions = props.sortedOptions

    const CustomTooltip = useMemo(
        () => createCustomTooltip(isOptionSelected),
        [isOptionSelected]
    )

    const maxValue: number | "dataMax" = useMemo(() => {
        return calculateMaxValue(data, isOptionSelected)
    }, [data, isOptionSelected])

    const [selectedOptions, unselectedOptions] = useMemo(() => {
        const selectedColorTable = getColorTable("500")
        const unselectedColorTable = getColorTable("100")

        const selected = []
        const unselected = []
        for (let i = sortedOptions.length - 1; i >= 0; i--) {
            const [option, value] = sortedOptions[i]

            if (isOptionSelected(option)) {
                const color = selectedColorTable[i % selectedColorTable.length]
                const optionObj = { option, value, color }
                selected.push(optionObj)
            } else {
                const color =
                    unselectedColorTable[i % unselectedColorTable.length]
                const optionObj = { option, value, color }
                unselected.push(optionObj)
            }
        }

        return [selected, unselected]
    }, [sortedOptions, isOptionSelected])

    return (
        <ResponsiveContainer>
            <RechartsLineChart
                data={data.map((result) => ({
                    key: result.key,
                    ...result.values,
                }))}
                margin={{
                    left: 12,
                    right: 12,
                    top: 20,
                    bottom: 20,
                }}
            >
                <CartesianGrid
                    vertical={false}
                    stroke={tailwindColors.gray[200]}
                />
                <XAxis
                    dataKey="key"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    tickFormatter={toLabel}
                    fontSize={12}
                />
                <YAxis
                    type="number"
                    axisLine={false}
                    tickLine={false}
                    fontSize={12}
                    domain={["dataMin", maxValue]}
                    allowDataOverflow
                />
                <Tooltip
                    content={<CustomTooltip />}
                    labelFormatter={toLabel}
                    wrapperStyle={{ zIndex: 1000 }}
                />
                {unselectedOptions.map(({ option, color }) => (
                    <Line
                        key={option}
                        name={option}
                        type="monotone"
                        dataKey={option}
                        stroke={color.replace("500", "100")}
                        style={{ filter: "grayscale(0.5)" }}
                        strokeWidth={4}
                        dot={false}
                        isAnimationActive={false}
                    />
                ))}
                {selectedOptions.map(({ option, color }) => (
                    <Line
                        key={option}
                        name={option}
                        type="monotone"
                        dataKey={option}
                        stroke={color}
                        strokeWidth={4}
                        dot={false}
                        isAnimationActive={false}
                    />
                ))}
            </RechartsLineChart>
        </ResponsiveContainer>
    )
}
