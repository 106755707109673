export function LoadingPulse(props: {
    rows: number
    color?: string
    height?: string
}) {
    const color = props.color || "bg-slate-200"
    const height = props.height || "h-2"
    const pulseDivs = Array.from({ length: props.rows }, (_, index) => (
        <div key={index} className={`${height} ${color} rounded`}></div>
    ))

    return (
        <div className="w-full">
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">{pulseDivs}</div>
            </div>
        </div>
    )
}
