import SuperTokens from "supertokens-auth-react"
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui"
import { ThirdPartyEmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui"
import { Routes, BrowserRouter, Route } from "react-router-dom"
import * as reactRouterDom from "react-router-dom"
import { AuthPage, SuperTokensConfig } from "./config"
import Call from "./components/call-page/Call"
import { WithNavbarLayout } from "./components/NavbarLayout"
import { CallsList } from "./components/calls-list/CallsList"
import { Settings } from "./components/settings/Settings"
import { About } from "./components/about/About"
import { GoogleApiDisclosure } from "./components/about/GoogleApiDisclosure"
import { TermsOfService } from "./components/about/TermsOfService"
import { Support } from "./components/about/Support"
import { ZoomDocumentation } from "./components/about/ZoomDocumentation"
import Resources from "./components/resources/Resources"
import { SharedCall } from "./components/call-page/SharedCall"
import { Schedule } from "./components/schedule/Schedule"
import { CallPrepTempPage } from "./components/call-prep/CallPrepTempPage"
import { DealsList } from "./components/deals/DealsList"
import { NotificationProvider } from "./providers/NotificationProvider"
import { Company } from "./components/company-view/Company"
import { Companies } from "./components/company-view/CompaniesList"
import { SuperTokensProviders } from "./SuperTokensProviders"
import { CallPrepPage } from "./components/call-prep/CallPrepPage"
import { QueryClientProvider } from "@tanstack/react-query"
import { ActivityPage } from "./components/activity/ActivityPage"
import { Home } from "./components/home-page/Home"
import OrgView from "./components/OrgView"
import { SessionAuth } from "supertokens-auth-react/recipe/session"
import { queryClient } from "./utils/reactQueryClient"
import { StrategicInsights } from "./components/strategic-insights/StrategicInsights"

SuperTokens.init(SuperTokensConfig)

function App() {
    return (
        <SuperTokensProviders>
            <NotificationProvider>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <Routes>
                            {/* This shows the login UI on "/auth" route */}
                            {getSuperTokensRoutesForReactRouterDom(
                                reactRouterDom,
                                [ThirdPartyEmailPasswordPreBuiltUI]
                            )}
                            <Route
                                path="/"
                                element={
                                    /* This protects the "/" route so that it shows
                                    <Home /> only if the user is logged in.
                                    Else it redirects the user to "/auth" */
                                    <SessionAuth>
                                        <OrgView />
                                    </SessionAuth>
                                }
                            />
                            <Route
                                path="/home"
                                element={
                                    <WithNavbarLayout>
                                        <Home />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/calls"
                                element={
                                    <WithNavbarLayout>
                                        <CallsList />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route path="/auth" element={<AuthPage />} />
                            <Route
                                path="/deals"
                                element={
                                    <WithNavbarLayout>
                                        <DealsList />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/companies"
                                element={
                                    <WithNavbarLayout>
                                        <Companies />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/companies/:domain"
                                element={
                                    <WithNavbarLayout>
                                        <Company />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/calls/:call_id"
                                element={
                                    <WithNavbarLayout>
                                        <Call />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/schedule"
                                element={
                                    <WithNavbarLayout>
                                        <Schedule />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/activity"
                                element={
                                    <WithNavbarLayout>
                                        <ActivityPage />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/resources"
                                element={
                                    <WithNavbarLayout>
                                        <Resources />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/shared/calls/:shared_call_id"
                                element={<SharedCall />}
                            />
                            <Route
                                path="/call-prep-test"
                                element={
                                    <WithNavbarLayout>
                                        <CallPrepTempPage />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/call-prep/:prep_sheet_id"
                                element={
                                    <WithNavbarLayout>
                                        <CallPrepPage />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/settings"
                                element={
                                    <WithNavbarLayout>
                                        <Settings />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/insights"
                                element={
                                    <WithNavbarLayout>
                                        <StrategicInsights />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/about/*"
                                element={
                                    <Routes>
                                        <Route path="/" element={<About />} />
                                        <Route
                                            path="google-api-disclosure"
                                            element={<GoogleApiDisclosure />}
                                        />
                                        <Route
                                            path="terms-of-service"
                                            element={<TermsOfService />}
                                        />
                                        <Route
                                            path="support"
                                            element={<Support />}
                                        />
                                        <Route
                                            path="zoom-documentation"
                                            element={<ZoomDocumentation />}
                                        />
                                    </Routes>
                                }
                            />
                        </Routes>
                    </BrowserRouter>
                </QueryClientProvider>
            </NotificationProvider>
        </SuperTokensProviders>
    )
}

export default App
