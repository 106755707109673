import { ReactElement, useEffect, useMemo, useState } from "react"
import { ICRMDeal, ICrmDealUpdate } from "../../types/Deal"
import {
    ColumnDef,
    Row,
    SortingState,
    Updater,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getGroupedRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import {
    convertDateToISODateString,
    getSimpleDate,
    getSimpleDateWithoutYear,
} from "../../utils/datetime"
import DealDetailsRow from "./DealDetailsRow"
import DetailsTableHeaderGroup from "../calls-list/DetailsTableHeaderGroup"
import { CompanyLogo } from "../company-view/CompanyLogo"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"
import { ScreenSize, screenLargerOrEqualTo } from "../../utils/screenSize"
import { ICrmDealStage } from "../crm/types/Crm"
import { ICompanyAnnotationRubricResults } from "../../types/Company"
import { QualificationScores } from "./QualificationScores"
import { StatusIcon } from "../company-view/Summary"
import { annotationTypes } from "../../utils/getAnnotationSections"
import clsx from "clsx"
import { Permission } from "../../types/Permission"
import { hasPermission } from "../../utils/Permissions"
import { useUser } from "../../providers/UserProvider"
import { ReactNode } from "react"

interface DealDetailsTableProps {
    deals: ICRMDeal[]
    dealStages: ICrmDealStage[]
    filterTerm: string
    groupByStage: boolean
    annotationTags: string[]
    annotationFilters: string[]
    updateDeal: (deal: ICrmDealUpdate) => Promise<any>
}

const DEAL_TABLE_SORT_CACHE_KEY = "deal-table-sorting"

export function DealsDetailsTable(props: DealDetailsTableProps) {
    // Check if we should display qualification scores
    const dealWithRubricScores = useMemo(() => {
        return props.deals.find(
            (deal) =>
                (deal.company?.qualification_rubric_scores?.length || 0) > 0
        )
    }, [props.deals])
    const hasQualificationScores = dealWithRubricScores !== undefined
    const qualificationScoreLength =
        dealWithRubricScores?.company?.qualification_rubric_scores?.length
    const groupByStage = props.groupByStage
    const annotationFilters = props.annotationFilters
    const annotationTags = props.annotationTags
    const dealStages = props.dealStages

    const user = useUser()
    const canEditDeals =
        (user && hasPermission(user, Permission.EDIT_CRM_DEALS)) || false
    const updateDeal = props.updateDeal

    const columns = useMemo<ColumnDef<ICRMDeal, any>[]>(() => {
        return [
            {
                id: "name",
                accessorFn: (row) => row.name,
                enableGlobalFilter: true,
            },
            {
                id: "overview",
                accessorFn: (row) => row,
                header: "Deal",
                cell: (info) => (
                    <DealOverviewCell
                        deal={info.getValue()}
                        annotationFilters={annotationFilters}
                    />
                ),
                enableGlobalFilter: true,
                sortingFn: sortDealByName,
            },
            {
                id: "last_call",
                accessorFn: (row) => row.company?.last_call_time || null,
                header: "Last Call",
                cell: (info) => <DateCell date={info.getValue()} />,
                enableGlobalFilter: false,
                size: 24,
            },
            {
                id: "next_call",
                accessorFn: (row) => row.company?.next_call_time || null,
                header: "Next Call",
                cell: (info) => <DateCell date={info.getValue()} />,
                enableGlobalFilter: false,
                size: 24,
            },
            {
                id: "qualification_rubric_scores",
                accessorFn: (row) => row.company?.qualification_rubric_scores,
                header: () => (
                    <RubricScoresHeader annotationTags={annotationTags} />
                ),
                enableGlobalFilter: false,
                cell: (info) => (
                    <QualificationScores
                        scores={info.getValue()}
                        length={qualificationScoreLength || 0}
                    />
                ),
                sortingFn: sortQualificationScores,
                size: 44,
            },
            {
                id: "stage",
                accessorFn: (row) => row.stage,
                header: "Deal Stage",
                enableGlobalFilter: false,
                cell: (info) => (
                    <EditableDealStageCell
                        value={info.getValue()}
                        dealStages={dealStages}
                        isEditable={canEditDeals}
                        onChange={async (newStage: ICrmDealStage) => {
                            await updateDeal({
                                id: info.row.original.id,
                                stage: newStage,
                            })
                        }}
                    />
                ),
                sortingFn: sortStageRow,
                size: 40,
                meta: {
                    isEditable: canEditDeals,
                },
            },
            {
                id: "stage_id",
                accessorFn: (row) => row.stage?.crm_id,
                enableGlobalFilter: false,
                cell: (info) => getValueOrEmpty(info.getValue()),
            },
            {
                id: "close_date",
                accessorFn: (row) => row.close_date,
                header: "Close Date",
                cell: (info) => (
                    <EditableCloseDateCell
                        value={info.getValue()}
                        isEditable={canEditDeals}
                        onChange={async (newDate: string) => {
                            await updateDeal({
                                id: info.row.original.id,
                                close_date: newDate,
                            })
                        }}
                    />
                ),
                enableGlobalFilter: false,
                size: 32,
                meta: {
                    isEditable: canEditDeals,
                },
            },
            {
                id: "amount",
                accessorFn: (row) => row.amount,
                header: "Amount",
                cell: (info) => (
                    <EditableAmountCell
                        value={info.getValue()}
                        isEditable={canEditDeals}
                        onChange={async (newAmount) => {
                            await updateDeal({
                                id: info.row.original.id,
                                amount: newAmount,
                            })
                        }}
                    />
                ),
                enableGlobalFilter: false,
                size: 24,
                meta: {
                    isEditable: canEditDeals,
                },
            },
        ]
    }, [
        qualificationScoreLength,
        annotationTags,
        annotationFilters,
        canEditDeals,
        dealStages,
        updateDeal,
    ])

    const showAllColumns: boolean = screenLargerOrEqualTo(ScreenSize.md)

    const defaultSorting: SortingState = [
        { id: "close_date", desc: false },
        { id: "name", desc: false },
    ]
    if (!showAllColumns) {
        // If we're only showing a subset of columns, we should sort by the name
        // otherwise it's confusing to the user.
        defaultSorting.shift()
    }

    const [sorting, setSorting] = useState<SortingState>(
        getCacheValue(DEAL_TABLE_SORT_CACHE_KEY, defaultSorting)
    )

    const grouping = useMemo(
        () => (groupByStage ? ["stage_id"] : []),
        [groupByStage]
    )

    // We need to presort the data by stage order so the gorups are ordered correctly
    const dealsSortedByStageOrder = useMemo(() => {
        return [...props.deals].sort((a, b) => sortStage(a.stage, b.stage))
    }, [props.deals])

    const table = useReactTable({
        data: dealsSortedByStageOrder,
        columns,

        enableSorting: true,

        state: {
            columnVisibility: {
                name: false,
                overview: true,
                next_call: showAllColumns,
                last_call: showAllColumns,
                stage: showAllColumns && (!groupByStage || canEditDeals), // Only show stage col when groupByStage is enabled to allow users to edit stage
                amount: showAllColumns,
                close_date: showAllColumns,
                qualification_rubric_scores:
                    showAllColumns && hasQualificationScores,
                stage_id: false,
            },
            globalFilter: props.filterTerm,
            sorting: sorting,
            grouping: grouping,
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),

        getGroupedRowModel: getGroupedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        autoResetExpanded: false, // Do not collapse groups when data changes

        globalFilterFn: "includesString",

        onSortingChange: (sortUpdater: Updater<SortingState>) => {
            // When we use onSortingChange, we need to maintain the sorting state ourselves in a useState
            // https://tanstack.com/table/v8/docs/api/features/sorting#onsortingchange
            const newSorting =
                sortUpdater instanceof Function
                    ? sortUpdater(sorting)
                    : sortUpdater
            setSorting(newSorting)
            setCacheValue(DEAL_TABLE_SORT_CACHE_KEY, newSorting)
        },
    })

    useMemo(() => table.toggleAllRowsExpanded(true), [table]) // Expand all groups by default

    return (
        <div>
            <div className="text-xs lg:text-sm">
                <table className="p-2 table-fixed w-full border-separate border-spacing-y-2">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <DetailsTableHeaderGroup
                                key={headerGroup.id}
                                headerGroup={headerGroup}
                            />
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <DealDetailsRow key={row.id} row={row} />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function RubricScoresHeader(props: { annotationTags: string[] }) {
    const tags = annotationTypes.filter((tag) =>
        props.annotationTags.includes(tag.tag)
    )
    return (
        <span className="space-x-[2px] w-44">
            {tags.map((tag) => (
                <span
                    key={tag.tag}
                    className="inline-flex h-[16px] w-[16px] border border-gray-300 bg-gray-200 rounded-full overflow-auto items-center justify-center text-xs"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content={`${tag.emoji} ${tag.label}`}
                >
                    {tag.acronym}
                </span>
            ))}
        </span>
    )
}

function DealOverviewCell(props: {
    deal: ICRMDeal
    annotationFilters: string[]
}) {
    const scores = props.deal.company?.qualification_rubric_scores
    const filteredScores: ICompanyAnnotationRubricResults[] | undefined =
        scores?.filter((score) => props.annotationFilters.includes(score.tag))
    const showSummary =
        props.annotationFilters.includes("summary") &&
        props.deal.company?.summary

    return (
        <div className="space-y-2 w-full">
            <div className="flex items-center space-x-2">
                <CompanyLogo
                    image_url={props.deal?.company?.image_url || null}
                />
                <span className="font-semibold text-base">
                    {props.deal.name}
                </span>
                <span>- {props.deal.owner_name}</span>
            </div>
            {showSummary && <div className="border-b border-gray-200" />}
            {showSummary && (
                <div className="text-gray-500">
                    {props.deal.company?.summary}
                </div>
            )}
            {filteredScores && filteredScores.length > 0 && (
                <div className="border-b border-gray-200" />
            )}
            {filteredScores &&
                filteredScores.map((score) => {
                    const tooltip = `<b>${score.name}:</b><br/>${score.feedback}`
                    return (
                        <div
                            key={score.tag}
                            className="flex gap-2 items-center w-fit"
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-html={tooltip}
                        >
                            <StatusIcon score={score.score} tag={score.tag} />
                            {score.feedback_headline}
                        </div>
                    )
                })}
        </div>
    )
}

function DateCell(props: { date: string | null }) {
    const displayValue = props.date
        ? getSimpleDateWithoutYear(props.date)
        : "--"
    // Show full date with year on hover
    const fullDate = props.date ? getSimpleDate(props.date) : ""
    return (
        <span
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={fullDate}
        >
            {displayValue}
        </span>
    )
}

// Generic editable cell component
function EditableCell<T>({
    value,
    onChange,
    isEditable,
    renderDisplay,
    renderEdit,
    validate = () => true,
}: {
    value: T | null
    onChange: (newValue: T) => Promise<any>
    isEditable: boolean
    renderDisplay: (value: T | null) => ReactNode
    renderEdit: (
        value: T | null,
        onChange: (newValue: T) => void,
        onBlur: () => void
    ) => ReactElement
    validate?: (newValue: T) => boolean
}) {
    const [isEditing, setIsEditing] = useState(false)
    const [editValue, setEditValue] = useState<T | null>(value)
    const [isUpdating, setIsUpdating] = useState(false)

    // This is needed to keep the value updated in the input field even if
    // it changes with a reload
    useEffect(() => {
        setEditValue(value)
    }, [value])

    const handleBlur = async () => {
        setIsEditing(false)
        if (editValue !== null && validate(editValue) && editValue !== value) {
            try {
                setIsUpdating(true)
                await onChange(editValue)
            } finally {
                setIsUpdating(false)
            }
        }
    }

    if (isUpdating) {
        return (
            <div
                className="h-8 w-full animate-pulse bg-gray-200 rounded-lg p-1"
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content="Updating..."
            />
        )
    }

    if (isEditable && isEditing) {
        return renderEdit(editValue, setEditValue, handleBlur)
    }

    return (
        <span
            className={clsx(
                "w-full border-gray-400 rounded-lg p-1",
                isEditable &&
                    "cursor-pointer hover:border hover:bg-white hover:cursor-text"
            )}
            onClick={() => {
                if (isEditable) {
                    setIsEditing(true)
                }
            }}
        >
            {renderDisplay(value)}
        </span>
    )
}

function EditableAmountCell({
    value,
    onChange,
    isEditable,
}: {
    value: number | null
    onChange: (newValue: number) => Promise<any>
    isEditable: boolean
}) {
    return (
        <EditableCell
            value={value}
            onChange={onChange}
            isEditable={isEditable}
            renderDisplay={(val) => (
                <div className="text-right w-full">
                    {val ? val.toLocaleString() : "--"}
                </div>
            )}
            renderEdit={(val, setVal, onBlur) => (
                <input
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={val?.toString() || ""}
                    onChange={(e) => {
                        const newVal = e.target.value
                        if (newVal === "" || !isNaN(Number(newVal))) {
                            setVal(newVal === "" ? 0 : Number(newVal))
                        }
                    }}
                    onBlur={onBlur}
                    onKeyDown={(e) => e.key === "Enter" && onBlur()}
                    className="w-full text-right border-gray-400 rounded-lg p-1"
                    autoFocus
                />
            )}
            validate={(val) => val === null || !isNaN(val)}
        />
    )
}

function EditableDealStageCell({
    value,
    dealStages,
    onChange,
    isEditable,
}: {
    value: ICrmDealStage | null
    dealStages: ICrmDealStage[]
    onChange: (newStage: ICrmDealStage) => Promise<any>
    isEditable: boolean
}) {
    return (
        <EditableCell
            value={value}
            onChange={onChange}
            isEditable={isEditable}
            renderDisplay={(val) => val?.name || "--"}
            renderEdit={(val, setVal, onBlur) => (
                <select
                    value={val?.crm_id || ""}
                    onChange={(e) => {
                        const newStage = dealStages.find(
                            (stage) => stage.crm_id === e.target.value
                        )
                        if (newStage) setVal(newStage)
                    }}
                    onBlur={onBlur}
                    onKeyDown={(e) => e.key === "Enter" && onBlur()}
                    className="w-full border border-gray-400 rounded-lg p-1"
                    autoFocus
                >
                    {dealStages.map((stage) => (
                        <option key={stage.crm_id} value={stage.crm_id}>
                            {stage.name}
                        </option>
                    ))}
                </select>
            )}
        />
    )
}

function EditableCloseDateCell({
    value,
    onChange,
    isEditable,
}: {
    value: string | null
    onChange: (newDate: string) => Promise<any>
    isEditable: boolean
}) {
    return (
        <EditableCell
            value={value}
            onChange={(newDate) =>
                onChange(convertDateToISODateString(newDate))
            }
            isEditable={isEditable}
            renderDisplay={(val) =>
                val ? getSimpleDateWithoutYear(val) : "--"
            }
            renderEdit={(val, setVal, onBlur) => (
                <input
                    type="date"
                    value={val ? new Date(val).toISOString().split("T")[0] : ""}
                    onChange={(e) => setVal(e.target.value)}
                    onBlur={onBlur}
                    onKeyDown={(e) => e.key === "Enter" && onBlur()}
                    className="w-full border-gray-400 rounded-lg p-1"
                    autoFocus
                />
            )}
            validate={(val) => val !== null && !isNaN(new Date(val).getTime())}
        />
    )
}

function getValueOrEmpty(value: string | null | undefined) {
    return value || "--"
}

function sortDealByName(rowA: Row<ICRMDeal>, rowB: Row<ICRMDeal>) {
    const valA = rowA.original.name
    const valB = rowB.original.name

    return valA.localeCompare(valB, undefined, { sensitivity: "base" })
}

function sortStageRow(
    rowA: Row<ICRMDeal>,
    rowB: Row<ICRMDeal>,
    columnId: string
) {
    const valA: ICrmDealStage | null = rowA.getValue(columnId)
    const valB: ICrmDealStage | null = rowB.getValue(columnId)

    return sortStage(valA, valB)
}

function sortStage(valA: ICrmDealStage | null, valB: ICrmDealStage | null) {
    const aOrder = valA?.order || 0
    const bOrder = valB?.order || 0
    return aOrder - bOrder
}

function sortQualificationScores(
    rowA: Row<ICRMDeal>,
    rowB: Row<ICRMDeal>,
    columnId: string
) {
    // Sort by sum of scores

    const valA: ICompanyAnnotationRubricResults[] | null =
        rowA.getValue(columnId)
    const valB: ICompanyAnnotationRubricResults[] | null =
        rowB.getValue(columnId)

    const sumA = valA?.reduce((acc, score) => acc + score.score, 0) || 0
    const sumB = valB?.reduce((acc, score) => acc + score.score, 0) || 0

    if (sumA === sumB) {
        return 0
    } else {
        return sumA < sumB ? 1 : -1
    }
}
