import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { Granularity, InsightResult } from "../../types/Insights"
import { DateTime } from "luxon"

export const insightQueries = createQueryKeys("insights", {
    get: (since?: DateTime, granularity?: Granularity) => ({
        queryKey: ["insights", since, granularity],
        queryFn: async () => {
            const params = {
                since: since?.toISODate(),
                granularity,
            }
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/insights`,
                {
                    params,
                }
            )
            return response.data as Record<string, InsightResult>
        },
    }),
})
