import { useQuery } from "@tanstack/react-query"
import { queries } from "../../api/queries"
import { LineChart } from "./line-chart/LineChart"
import { ChartType, Granularity, InsightResult } from "../../types/Insights"
import LoadingSpinner from "../common/LoadingSpinner"
import { DateTime } from "luxon"
import { useMemo, useState } from "react"
import Select from "react-select"
import colors from "tailwindcss/colors"
import { ErrorPage } from "../common/errorPage"
import _ from "lodash"
import { BetaLabel } from "../common/BetaLabel"

export function StrategicInsights() {
    const [sinceDate, setSinceDate] = useState<DateTime>(
        DateTime.now().minus({ months: 6 }).startOf("day")
    )
    const [granularity, setGranularity] = useState<Granularity>()

    const { data, isPending, isError, error } = useQuery({
        ...queries.insights.get(sinceDate, granularity),
        staleTime: Infinity,
    })

    const sortedInsights = useMemo(() => {
        const insights = Object.values(data ?? {}) as InsightResult[]
        return _.orderBy(
            insights,
            [(insight) => insight.display_name.toLowerCase()],
            ["asc"]
        )
    }, [data])

    if (isPending) return <LoadingSpinner />

    if (isError) return <ErrorPage error={error} />

    return (
        <section className="my-6 rounded-lg px-8 py-6 space-y-4">
            <div className="flex flex-col justify-between gap-2">
                <div className="flex flex-row items-center gap-2">
                    <h1 className="text-2xl font-bold">Strategic Insights</h1>
                    <BetaLabel />
                </div>
                <BetaNotice />
                <p className="text-gray-500">
                    Strategic Insights help you identify trends and
                    opportunities from your{" "}
                    <span className="font-semibold">Glyphic call data</span>.
                </p>
                <div className="flex flex-col mt-4 space-y-1">
                    <span className="text-gray-900 font-semibold">Date</span>
                    <DateSelect
                        sinceDate={sinceDate}
                        setSinceDate={setSinceDate}
                        setGranularity={setGranularity}
                    />
                </div>
            </div>
            <div className="space-y-4">
                {sortedInsights.map((insight, index) => {
                    switch (insight.chart_type) {
                        case ChartType.LINE:
                            return (
                                <LineChart
                                    key={index}
                                    insightName={insight.display_name}
                                    data={insight.data}
                                    granularity={insight.granularity}
                                />
                            )
                        default:
                            return null
                    }
                })}
            </div>
        </section>
    )
}

function BetaNotice() {
    return (
        <p className="text-sm text-gray-500 bg-yellow-50 border-2 border-yellow-200 p-2 rounded-lg w-fit">
            <span className="font-semibold">Note:</span> This is a beta feature
            in active development. If you have any questions or feedback, please
            reach out to us at{" "}
            <a
                className="text-blue-500 underline"
                href="mailto:chat@glyphic.ai"
            >
                chat@glyphic.ai
            </a>
            .
        </p>
    )
}

function DateSelect(props: {
    sinceDate: DateTime
    setSinceDate: (date: DateTime) => void
    setGranularity: (granularity: Granularity) => void
}) {
    const dateOptions = useMemo(
        () => [
            {
                label: "Last 30 days",
                value: DateTime.now().minus({ days: 30 }).startOf("day"),
                granularity: Granularity.WEEKLY,
            },
            {
                label: "Last 3 months",
                value: DateTime.now().minus({ months: 3 }).startOf("day"),
                granularity: Granularity.WEEKLY,
            },
            {
                label: "Last 6 months",
                value: DateTime.now().minus({ months: 6 }).startOf("day"),
                granularity: Granularity.WEEKLY,
            },
            {
                label: "Last 9 months",
                value: DateTime.now().minus({ months: 9 }).startOf("day"),
                granularity: Granularity.MONTHLY,
            },
            {
                label: "Last 12 months",
                value: DateTime.now().minus({ months: 12 }).startOf("day"),
                granularity: Granularity.MONTHLY,
            },
            {
                label: "Last 18 months",
                value: DateTime.now().minus({ months: 18 }).startOf("day"),
                granularity: Granularity.MONTHLY,
            },
        ],
        []
    )

    const currentOption = useMemo(() => {
        return (
            dateOptions.find(
                (option) =>
                    option.value?.toMillis() === props.sinceDate?.toMillis()
            ) || null
        )
    }, [props.sinceDate, dateOptions])

    return (
        <Select
            options={dateOptions}
            className="w-40 text-sm"
            styles={{
                control: (provided) => ({
                    ...provided,
                    borderColor: colors.gray[200],
                    borderRadius: "0.5rem",
                    boxShadow: "none",
                    "&:hover": {
                        borderColor: colors.gray[300],
                        backgroundColor: colors.gray[50],
                    },
                }),
                indicatorSeparator: () => ({
                    display: "none",
                }),
                menu: (provided: any) => ({
                    ...provided,
                    padding: "0px 4px 0px 4px",
                    borderRadius: "0.5rem",
                }),
                option: (provided: any) => ({
                    ...provided,
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "0.5rem",
                    "&:hover": {
                        backgroundColor: colors.gray[100],
                    },
                }),
                dropdownIndicator: (provided: any) => ({
                    ...provided,
                    color: colors.gray[500],
                    "&:hover": { color: colors.gray[600] },
                    width: "2rem",
                }),
            }}
            onChange={(option) => {
                if (!option) return
                props.setSinceDate(option.value)
                props.setGranularity(option.granularity)
            }}
            value={currentOption}
            isSearchable={false}
        />
    )
}
