export interface InsightResult {
    display_name: string
    chart_type: ChartType
    granularity: Granularity
    data: InsightDateResult[]
}
export interface InsightDateResult {
    key: string
    values: Record<string, number>
}

export enum ChartType {
    LINE = "line",
}

export enum Granularity {
    WEEKLY = "weekly",
    MONTHLY = "monthly",
}
